// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfmsdynamiccrmintegrationpaymenttransactionrecordsync from "../../blocks/cfmsdynamiccrmintegrationpaymenttransactionrecordsync/src/Cfmsdynamiccrmintegrationpaymenttransactionrecordsync";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Cfmsbusinesscentralintegration2 from "../../blocks/cfmsbusinesscentralintegration2/src/Cfmsbusinesscentralintegration2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import Cfadhocreporting21 from "../../blocks/cfadhocreporting21/src/Cfadhocreporting21";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cfpaynowintegration from "../../blocks/cfpaynowintegration/src/Cfpaynowintegration";
import Msdynamiccrmintegrationcatalogmanagement from "../../blocks/msdynamiccrmintegrationcatalogmanagement/src/Msdynamiccrmintegrationcatalogmanagement";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Powerbiintegrationreports3rdpartyreports from "../../blocks/powerbiintegrationreports3rdpartyreports/src/Powerbiintegrationreports3rdpartyreports";
import Cfinstalmentpayment from "../../blocks/cfinstalmentpayment/src/Cfinstalmentpayment";
import Cfmsdynamiccrmintegrationuploadeddocumentsync from "../../blocks/cfmsdynamiccrmintegrationuploadeddocumentsync/src/Cfmsdynamiccrmintegrationuploadeddocumentsync";
import Cfmsbusinesscentralintegrationinvoiceissuing from "../../blocks/cfmsbusinesscentralintegrationinvoiceissuing/src/Cfmsbusinesscentralintegrationinvoiceissuing";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Cfwhatsappintegration211 from "../../blocks/cfwhatsappintegration211/src/Cfwhatsappintegration211";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Cfsingapssintegration from "../../blocks/cfsingapssintegration/src/Cfsingapssintegration";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cfpowerbiintegrationreportssystemgeneratedreports from "../../blocks/cfpowerbiintegrationreportssystemgeneratedreports/src/Cfpowerbiintegrationreportssystemgeneratedreports";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Cfogdashboard from "../../blocks/cfogdashboard/src/Cfogdashboard";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Msdynamiccrmintegrationuserregistrationmanagement from "../../blocks/msdynamiccrmintegrationuserregistrationmanagement/src/Msdynamiccrmintegrationuserregistrationmanagement";


const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfmsdynamiccrmintegrationpaymenttransactionrecordsync:{
 component:Cfmsdynamiccrmintegrationpaymenttransactionrecordsync,
path:"/Cfmsdynamiccrmintegrationpaymenttransactionrecordsync"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Cfmsbusinesscentralintegration2:{
 component:Cfmsbusinesscentralintegration2,
path:"/Cfmsbusinesscentralintegration2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
Cfadhocreporting21:{
 component:Cfadhocreporting21,
path:"/Cfadhocreporting21"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Cfpaynowintegration:{
 component:Cfpaynowintegration,
path:"/Cfpaynowintegration"},
Msdynamiccrmintegrationcatalogmanagement:{
 component:Msdynamiccrmintegrationcatalogmanagement,
path:"/Msdynamiccrmintegrationcatalogmanagement"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Powerbiintegrationreports3rdpartyreports:{
 component:Powerbiintegrationreports3rdpartyreports,
path:"/Powerbiintegrationreports3rdpartyreports"},
Cfinstalmentpayment:{
 component:Cfinstalmentpayment,
path:"/Cfinstalmentpayment"},
Cfmsdynamiccrmintegrationuploadeddocumentsync:{
 component:Cfmsdynamiccrmintegrationuploadeddocumentsync,
path:"/Cfmsdynamiccrmintegrationuploadeddocumentsync"},
Cfmsbusinesscentralintegrationinvoiceissuing:{
 component:Cfmsbusinesscentralintegrationinvoiceissuing,
path:"/Cfmsbusinesscentralintegrationinvoiceissuing"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Cfwhatsappintegration211:{
 component:Cfwhatsappintegration211,
path:"/Cfwhatsappintegration211"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Cfsingapssintegration:{
 component:Cfsingapssintegration,
path:"/Cfsingapssintegration"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Cfpowerbiintegrationreportssystemgeneratedreports:{
 component:Cfpowerbiintegrationreportssystemgeneratedreports,
path:"/Cfpowerbiintegrationreportssystemgeneratedreports"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Cfogdashboard:{
 component:Cfogdashboard,
path:"/Cfogdashboard"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Msdynamiccrmintegrationuserregistrationmanagement:{
 component:Msdynamiccrmintegrationuserregistrationmanagement,
path:"/Msdynamiccrmintegrationuserregistrationmanagement"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;